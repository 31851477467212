<template>
  <div>
    <div class="d-flex mt-2">
      <div class="navigate-space">
        <b-button
          v-if="testIndex != 0 && testsFilter.length > 1"
          size="sm"
          variant="none"
          class="navigate-left"
          v-b-tooltip.v-secondary.noninteractive="`Ir al anterior`"
          @click="previousTabs"
        >
          <b-icon icon="chevron-left"></b-icon>
        </b-button>
      </div>
      <strong class="border-bottom pb-1">Evaluar {{ test.title }}</strong>

      <div class="navigate-space">
        <b-button
          v-if="testIndex != testsFilter.length - 1"
          class="navigate-right"
          size="sm"
          variant="none"
          v-b-tooltip.v-secondary.noninteractive="`Ir al siguiente`"
          @click="nextTabs"
        >
          <b-icon icon="chevron-right"></b-icon>
        </b-button>
      </div>
    </div>
    <div class="mt-2 border-bottom" v-if="user_id != null">
      <div class="ml-2 text-left mb-1">
        <span
          v-if="
            !evaluateeTest ||
            (evaluateeTest && evaluateeTest.start_time == null)
          "
        >
          Estado:
          <strong class="text-red"> No iniciado </strong>
        </span>
        <span v-else-if="evaluateeTest">
          Estado: Iniciado el
          <strong>
            {{ evaluateeTest.start_time | FormatToDateTime }}
          </strong>
        </span>
      </div>
    </div>
    <div
      class="mt-2 mb-2 border-bottom text-left"
      v-if="evaluateeTest && evaluateeTest.student_attachments.length > 0"
    >
      <template
        ><span class="ml-2"> Adjuntos del Evaluado: </span>
        <FileContainer
          class="ml-2"
          :Files="evaluateeTest.student_attachments"
          :allows_crud="false"
        ></FileContainer>
      </template>
    </div>
    <div class="mt-0">
      <b-form-group
        :label="
          evaluateeTest && evaluateeTest.manual_score == null
            ? 'Puntaje propuesto'
            : 'Puntaje Máximo'
        "
        label-cols="4"
        label-cols-sm="8"
        class="mb-0 mx-2 py-1 text-left border-bottom"
      >
        <div class="mt-2">
          <span class="automated-score">
            <span v-if="evaluateeTest && evaluateeTest.manual_score == null">
              {{ automatedScore }}
              /
            </span>
            {{ test.max_score }}
          </span>
        </div>
      </b-form-group>
      <div class="d-flex mb-0 mx-2 py-1 text-left border-bottom">
        <div class="mt-1">Puntaje asignado:</div>
        <div class="mr-0 ml-auto">
          <div class="d-flex">
            <b-button
              v-if="
                evaluateeTest &&
                evaluateeTest.manual_score != null &&
                evaluatorCanQualify
              "
              class="m-0 mr-1 p-0"
              variant="none"
              v-b-tooltip.v-secondary.noninteractive="
                'Aplicar Puntaje Automático'
              "
              @click="patchEvaluateeTest({ manual_score: null })"
            >
              <b-icon icon="arrow-repeat" style="color: white"></b-icon>
            </b-button>
            <b-form-input
              id="manual-score-input"
              type="number"
              class="mr-1 manual-score-input"
              :disabled="
                !user_id || isNaN(evaluatee_test.id) || !evaluatorCanQualify
              "
              min="0"
              v-model.number="evaluatee_test.manual_score"
            ></b-form-input>
          </div>
        </div>
      </div>
      <!-- <b-form-group
        label="Puntaje asignado:"
        label-cols="2"
        label-cols-sm="8"
        class="mb-0 mx-2 py-1 text-left border-bottom"
      >
        <div class="d-flex">
          <b-form-input
            id="manual-score-input"
            type="number"
            class="manual-score-input mt-1"
            :disabled="
              !user_id || isNaN(evaluatee_test.id) || !evaluatorCanQualify
            "
            min="0"
            v-model.number="evaluatee_test.manual_score"
          ></b-form-input>
          <b-button
            v-if="evaluateeTest && evaluateeTest.manual_score != null"
            class="m-0 p-0"
            variant="none"
            v-b-tooltip.v-secondary.noninteractive="
              'Aplicar Puntaje Automático'
            "
            @click="patchEvaluateeTest({ manual_score: null })"
          >
            <b-icon icon="arrow-repeat" style="color: white"></b-icon>
          </b-button>
        </div>
      </b-form-group> -->
      <div
        v-if="
          evaluatee_test &&
          institution &&
          institution.internal_use_id != 'duoc_uc'
        "
        class="instrument-grade-input border-bottom mx-2 pt-1 pb-1"
      >
        <span class="text-left"
          >Nota del
          {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}:
        </span>
        <div class="mr-4">
          {{ getInstrumentFinalScore() }}
        </div>
      </div>
      <div class="border-bottom">
        <div
          v-if="user_id && institution && evaluatorCanQualify"
          class="btn-container ml-2"
        >
          <b-button
            class="btn-save mt-2 ml-2 mb-2 mr-2 w-50"
            :class="{ 'w-100': evaluatee_test.is_published }"
            size="sm"
            @click="patchQuestionTestScore(evaluatee_test.manual_score)"
          >
            Guardar
          </b-button>
          <b-button
            v-if="!evaluatee_test.is_published && missingRubricCriteria == 0"
            class="btn-save mt-2 ml-2 mr-2 w-50 mb-2"
            variant="primary"
            size="sm"
            @click="publicInstrument(evaluatee_test.manual_score)"
            v-b-tooltip.v-secondary.top.noninteractive="
              `Publicar ${$getVisibleNames(
                'evaluations2.test',
                false,
                'Instrumento'
              )}`
            "
          >
            Publicar
          </b-button>
          <div
            v-if="!evaluatee_test.is_published && missingRubricCriteria > 0"
            v-b-tooltip.v-danger.top.noninteractive="
              `Faltan ${missingRubricCriteria} ${$getVisibleNames(
                'manual.dimension',
                true,
                'Dimensiones'
              )} por evaluar`
            "
            class="w-50 mr-3"
          >
            <b-button
              class="btn-save mt-2 ml-2 mr-2"
              variant="primary"
              size="sm"
              :disabled="missingRubricCriteria > 0"
            >
              Publicar
            </b-button>
          </div>
        </div>
        <div
          v-if="
            user_id &&
            institution &&
            evaluatorCanQualify &&
            newTestGroup &&
            !isNaN(evaluatee_test.id)
          "
          class="btn-container ml-2"
        >
          <b-button
            class="btn-save mt-2 ml-2 mb-2 mr-2"
            size="sm"
            @click="
              $bvModal.show(
                `copy-evaluatee_test-student-group-modal-${evaluatee_test.id}${test.id}`
              )
            "
          >
            Replicar nota al grupo
          </b-button>
          <b-modal
            :id="`copy-evaluatee_test-student-group-modal-${evaluatee_test.id}${test.id}`"
            title="Seleccionar Evaluados"
            size="lg"
            hide-footer
            @hide="
              () => {
                selected_users = [];
                select_all = false;
                select_copy_feedback = false;
              }
            "
          >
            <GenericBTable
              :display_id="`GenericBTable-copy-evaluatee_test-student-group-modal-${evaluatee_test.id}${test.id}`"
              :items="filterListReplyScore"
              :fields="fields"
              :columns_display="false"
              :show_pagination="true"
              :search_filter="false"
              :default_striped="false"
              :default_borderless="false"
              :pagination="100"
            >
              <template #head(selected)>
                <b-form-checkbox
                  v-model="select_all"
                  @change="selectAllUsers"
                ></b-form-checkbox>
              </template>
              <template #cell(selected)="row">
                <div class="d-flex">
                  <b-checkbox
                    class="ml-auto mr-auto"
                    :checked="selected_users.includes(row.item.id)"
                    @change="changeSelected(row.item)"
                  ></b-checkbox>
                </div>
              </template>
              <template #cell(first_name)="row">
                {{ row.item.user_data ? row.item.user_data.identifier : "" }}
                {{ row.item.first_name }} {{ row.item.last_name }}
              </template>
            </GenericBTable>
            <div class="d-flex">
              <div class="ml-auto mr-0 d-flex">
                <b-form-checkbox
                  v-model="select_copy_feedback"
                  class="m-0 mt-1 mr-3"
                >
                  Copiar Retroalimentación
                </b-form-checkbox>
                <b-button
                  v-if="selected_users.length > 0 && show_button_reply_score"
                  size="sm"
                  @click="replcateTestScore"
                  v-b-tooltip.v-secondary.noninteractive="
                    `${selected_users.length} evaluados seleccionados.`
                  "
                >
                  Aplicar
                </b-button>
                <b-button v-else size="sm" disabled> Aplicar</b-button>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
      <div
        class="mt-2 mb-1 text-left"
        v-if="
          test.student_can_upload_attachments &&
          evaluateeTest &&
          (evaluateeTest.evaluator_attachments.length > 0 ||
            evaluatorCanQualify)
        "
      >
        <template>
          <div class="d-flex">
            <div class="ml-2 w-100">Adjunto del Evaluador:</div>
            <button-add
              v-if="
                evaluatorCanQualify &&
                evaluateeTest.evaluator_attachments.length < 5
              "
              @click="$bvModal.show(`modal-select-file-${test.id}-${user_id}`)"
              size="sm"
              v-b-tooltip.v-secondary="`Adjuntar Archivo`"
              class="mr-2 text-white pt-0"
            ></button-add>
          </div>
          <b-modal
            :id="`modal-select-file-${test.id}-${user_id}`"
            title="Seleccionar Archivos"
            hide-footer
          >
            <FileSelector @created="slotCreatedFile"></FileSelector>
          </b-modal>
          <div
            :class="{
              'file-container-special-scroll':
                evaluateeTest.evaluator_attachments.length > 2,
            }"
          >
            <FileContainer
              v-if="evaluateeTest"
              class="ml-2"
              :Files="evaluateeTest.evaluator_attachments"
              :allows_crud="evaluatorCanQualify"
              @deleteFile="slotDeleteEvaluatorFile"
            ></FileContainer>
          </div>
        </template>
      </div>
      <div v-if="evaluatee_test" class="mx-2">
        <div class="text-left">
          <span>Retroalimentación:</span>
        </div>
        <div
          v-b-tooltip.v-secondary="
            user_id && institution && evaluatorCanQualify
              ? 'Editar Retroalimentación'
              : ''
          "
          class="instrument-form-feedback rich-text-content mb-2 mt-1"
          v-html="evaluatee_test.feedback"
          @click="
            if (user_id && institution && evaluatorCanQualify)
              $bvModal.show(
                `edit-evaluatee_test-feedback-modal-${evaluatee_test.id}`
              );
          "
        ></div>
        <b-modal
          :id="`edit-evaluatee_test-feedback-modal-${evaluatee_test.id}`"
          title="Editar Retroalimentación"
          size="lg"
          hide-footer
        >
          <NewRichTextEditor
            :Object="evaluatee_test"
            :Text="evaluatee_test.feedback"
            :permit_blank="true"
            @save="patchFeedback"
            @close="
              $bvModal.hide(
                `edit-evaluatee_test-feedback-modal-${evaluatee_test.id}`
              )
            "
          ></NewRichTextEditor>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "EvaluatorTestScore",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    FileContainer: () => import("@/components/reusable/FileContainer"),
    FileSelector: () => import("@/components/reusable/FileSelector"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    test: {
      type: Object,
      required: true,
    },
    user_id: {
      type: Number,
    },
    // para restringir que el evaluador pueda colocar notas fuera del periodo actual con respecto al de la sección.
    evaluatorCanQualify: {
      type: Boolean,
      default: false,
    },
    // evaluateeTest: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       id: generateUniqueId(),
    //       automated_score: null,
    //       manual_score: null,
    //       final_score: null,
    //       is_finished: false,
    //       start_time: null,
    //       evaluatee: this.user_id,
    //       test: this.test.id,
    //       attachments: [],
    //     };
    //   },
    // },
  },
  data() {
    return {
      evaluatee_test: {
        id: generateUniqueId(),
        automated_score: null,
        manual_score: null,
        final_score: null,
        is_finished: false,
        start_time: null,
        feedback: "",
        is_corrected: false,
        is_published: false,
        evaluatee: this.user_id,
        test: this.test.id,
        attachments: [],
      },
      selected_users: [],
      show_button_reply_score: true,
      select_all: false,
      select_copy_feedback: false,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      users: names.USERS,
      tests: names.NEW_TESTS,
      evaluatee_tests: names.EVALUATEE_TESTS,
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      new_test_groups: names.NEW_TEST_GROUPS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      evaluatee_redaction_questions: names.EVALUATEE_REDACTION_QUESTIONS,
      evaluatee_multiple_choice_questions:
        names.EVALUATEE_MULTIPLE_CHOICE_QUESTIONS,
      evaluatee_multiple_choice_question_options:
        names.EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
      evaluatee_matching_questions: names.EVALUATEE_MATCHING_QUESTIONS,
      rubricInstances: names.NEW_RUBRIC_CRITERIAS,
      EvaRubCriPerLevScore: names.EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS,
    }),
    newTestGroup() {
      if (this.user_id)
        return this.new_test_groups.find(
          (x) => x.test == this.test.id && x.students.includes(this.user_id)
        );
      else return null;
    },
    testsFilter() {
      if (this.user_id)
        return this.tests.filter(
          (x) =>
            x.evaluation == this.test.evaluation &&
            x.evaluatees.includes(this.user_id)
        );
      else
        return this.tests.filter((x) => x.evaluation == this.test.evaluation);
    },
    filterListReplyScore() {
      if (!this.newTestGroup || !this.user_id) return [];
      return this.users.filter(
        (x) => this.newTestGroup.students.includes(x.id) && x.id != this.user_id
      );
    },
    testIndex() {
      return this.testsFilter.findIndex((x) => x.id == this.test.id);
    },
    evaluateeTest() {
      return this.evaluatee_tests.find(
        (x) => x.evaluatee == this.user_id && x.test == this.test.id
      );
    },
    rubric() {
      return this.rubrics.find((x) => x.test == this.test.id);
    },
    missingRubricCriteria() {
      // Todos los rubric_criteria que se colocó un check
      const criteriaIds = this.EvaRubCriPerLevScore.filter(
        (x) => x.evaluatee == this.user_id
      ).map((x) => x.rubric_criteria);

      // Filtrar todos los criterios para luego obtener los que faltan
      const missingCriteria = this.rubricInstances.filter(
        (x) => x.rubric == this.rubric.id && !criteriaIds.includes(x.id)
      );
      return missingCriteria.length;
    },
    redactionQuestions() {
      return this.redaction_questions.filter((x) => x.test == this.test.id);
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.test.id
      );
    },
    matchingQuestions() {
      return this.matching_questions.filter((x) => x.test == this.test.id);
    },
    automatedScore() {
      let automated_score = 0;
      if (this.user_id == null) return automated_score;
      if (
        this.rubric &&
        this.evaluateeTest &&
        this.evaluateeTest.automated_score > 0
      )
        return parseFloat(this.evaluateeTest.automated_score.toFixed(2));
      this.evaluatee_redaction_questions
        .filter(
          (x) =>
            x.evaluatee == this.user_id &&
            this.redactionQuestions
              .map((rq) => rq.id)
              .includes(x.redaction_question)
        )
        .forEach((element) => {
          if (element.manual_score != null) {
            automated_score += parseFloat(element.manual_score);
          } else if (element.automated_score != null) {
            automated_score += parseFloat(element.automated_score);
          }
        });
      this.evaluatee_multiple_choice_questions
        .filter(
          (x) =>
            x.evaluatee == this.user_id &&
            this.multipleChoiceQuestions
              .map((rq) => rq.id)
              .includes(x.multiple_choice_question)
        )
        .forEach((element) => {
          if (element.manual_score != null) {
            automated_score += parseFloat(element.manual_score);
          } else if (
            element.automated_score != null &&
            element.automated_score > 0
          ) {
            automated_score += parseFloat(element.automated_score);
          }
        });
      this.evaluatee_matching_questions
        .filter(
          (x) =>
            x.evaluatee == this.user_id &&
            this.matchingQuestions
              .map((rq) => rq.id)
              .includes(x.matching_question)
        )
        .forEach((element) => {
          if (element.manual_score != null) {
            automated_score += parseFloat(element.manual_score);
          } else if (
            element.automated_score != null &&
            element.automated_score > 0
          ) {
            automated_score += parseFloat(element.automated_score);
          }
        });
      return automated_score;
    },
    fields() {
      return [
        { key: "selected", label: "", display_column: true },
        {
          key: "first_name",
          label: "Evaluados",
          sortable: false,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
      ];
    },
  },
  methods: {
    previousTabs() {
      this.$emit("previous_tabs");
    },
    nextTabs() {
      this.$emit("next_tabs");
    },
    patchQuestionTestScore(value) {
      let manual_score = value;
      if (value == null || value == "") manual_score = 0;
      if (manual_score >= 0 && manual_score != null) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          toast("Puntaje de Instrumento actualizado.");
          if (
            value != this.automatedScore ||
            (this.evaluateeTest && this.evaluateeTest.manual_score != null)
          ) {
            this.patchEvaluateeTest({
              manual_score: manual_score,
              is_corrected: true,
              feedback: this.evaluatee_test.feedback,
            });
          } else {
            this.patchEvaluateeTest({
              is_corrected: true,
              feedback: this.evaluatee_test.feedback,
            });
          }
        }, 500);
      }
    },
    patchFeedback(object, text) {
      this.evaluatee_test.feedback = text;
      toast("Retroalimentación actualizada.");
      this.patchEvaluateeTest({ feedback: text });
    },
    publicInstrument(value) {
      let manual_score = value;
      if (value == null || value == "") manual_score = 0;
      // const missingDimensions = this.missingRubricCriteria;
      // if (missingDimensions > 0) {
      //   this.$swal({
      //     title: "Dimensiones faltantes",
      //     text: `Te faltan ${missingDimensions} ${this.$getVisibleNames(
      //       "manual.dimension",
      //       true,
      //       "Dimensiones"
      //     )} por evaluar.`,
      //     type: "warning",
      //     showCancelButton: true,
      //     confirmButtonText: "Continuar de todas formas",
      //     cancelButtonText: "Cancelar",
      //   }).then((result) =>{
      //     if(result.value){

      //     }
      //   })
      // }
      if (manual_score >= 0 && manual_score != null) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          toast("Puntaje publicado.");
          if (
            value != this.automatedScore ||
            (this.evaluateeTest && this.evaluateeTest.manual_score != null)
          )
            this.patchEvaluateeTest({
              manual_score: manual_score,
              feedback: this.evaluatee_test.feedback,
              is_corrected: true,
              is_published: true,
            });
          else
            this.patchEvaluateeTest({
              feedback: this.evaluatee_test.feedback,
              is_corrected: true,
              is_published: true,
            });
        }, 500);
      }
    },
    patchEvaluateeTest(item) {
      if (!isNaN(this.evaluatee_test.id)) {
        const payload = {
          evaluatee_test_id: this.evaluatee_test.id,
          item: item,
        };
        this.$store.dispatch(names.PATCH_EVALUATEE_TEST, payload).then(() => {
          this.$store.dispatch(names.FETCH_EVALUATEE_EVALUATIONS, {
            evaluatee_id: this.user_id,
            evaluation_id: this.test.evaluation,
          });
        });
      } else if (this.user_id != null && isNaN(this.evaluatee_test.id)) {
        let manual_score = this.evaluatee_test.manual_score;
        if (
          this.evaluatee_test.manual_score == "" ||
          this.evaluatee_test.manual_score == 0
        )
          manual_score = null;
        this.$store
          .dispatch(names.POST_EVALUATEE_TEST, {
            manual_score: manual_score,
            is_finished: false,
            feedback: this.evaluatee_test.feedback,
            evaluatee: this.user_id,
            is_corrected: true,
            test: this.test.id,
            attachments: [],
            student_attachments: [],
            evaluator_attachments: [],
          })
          .then((response) => {
            this.$store.dispatch(names.FETCH_EVALUATEE_EVALUATIONS, {
              evaluatee_id: this.user_id,
              evaluation_id: this.test.evaluation,
            });
            this.evaluatee_test = response;
          });
      }
    },
    getInstrumentFinalScore() {
      if (this.institution) {
        const appr_grade = this.institution.appr_grade;
        const exigence_percentage = this.institution.exigence_percentage / 100;
        const min_grade = this.institution.min_grade;
        const max_grade = this.institution.max_grade;
        const max_score = this.test.max_score;
        const automated_score =
          this.evaluatee_test &&
          this.evaluatee_test.automated_score &&
          this.evaluatee_test.automated_score > 0 &&
          this.evaluatee_test.automated_score != null
            ? this.evaluatee_test.automated_score
            : 0;
        const manual_score = this.evaluatee_test.manual_score;
        let tem_grade = min_grade;
        let obtained_score = 0;
        if (manual_score != null) {
          obtained_score = manual_score;
        } else {
          obtained_score = automated_score;
        }
        if (obtained_score < exigence_percentage * max_score) {
          tem_grade =
            ((appr_grade - min_grade) * obtained_score) /
              (exigence_percentage * max_score) +
            min_grade;
        } else {
          tem_grade =
            ((max_grade - appr_grade) *
              (obtained_score - exigence_percentage * max_score)) /
              (max_score * (1 - exigence_percentage)) +
            appr_grade;
        }
        if (isNaN(tem_grade)) return parseFloat(min_grade.toFixed(2));
        if (tem_grade > max_grade) return parseFloat(max_grade.toFixed(2));
        else if (tem_grade < min_grade) return parseFloat(min_grade.toFixed(2));
        else return parseFloat(tem_grade.toFixed(2));
      }
    },
    selectAllUsers() {
      if (this.select_all) {
        this.filterListReplyScore
          .filter((x) => !this.selected_users.includes(x.id))
          .forEach((x) => {
            this.selected_users.push(x.id);
          });
      } else {
        this.selected_users = [];
      }
    },
    changeSelected(item) {
      const index = this.selected_users.findIndex((x) => x == item.id);
      if (index != -1) this.selected_users.splice(index, 1);
      else this.selected_users.push(item.id);
      if (this.selected_users.length == this.filterListReplyScore.length)
        this.select_all = true;
      else this.select_all = false;
    },
    replcateTestScore() {
      this.show_button_reply_score = false;
      this.$restful
        .Post(`/evaluations2/replicate_test_manual_scores/`, {
          origin_evaluatee_test: this.evaluatee_test.id,
          target_evaluatees: this.selected_users.join(","),
          copy_feedback: this.select_copy_feedback,
        })
        .then(() => {
          this.$bvModal.hide(
            `copy-evaluatee_test-student-group-modal-${this.evaluatee_test.id}${this.test.id}`
          );
          this.show_button_reply_score = true;
          toast("Notas Replicadas");
        });
    },
    slotCreatedFile(file) {
      if (this.user_id != null && this.test && file) {
        let evaluator_attachments = this.evaluateeTest.evaluator_attachments;
        evaluator_attachments.push(file.id);
        this.patchEvaluateeTest({
          evaluator_attachments: evaluator_attachments,
        });
        this.$bvModal.hide(`modal-select-file-${this.test.id}-${this.user_id}`);
      }
    },
    slotDeleteEvaluatorFile(file_id) {
      if (this.evaluateeTest)
        this.$store.commit("MUTATE_DELETE_EVALUATOR_FILE_IN_EVALUATEE_TEST", {
          evaluatee_test_id: this.evaluateeTest.id,
          file_id: file_id,
        });
    },
  },
  watch: {
    evaluateeTest() {
      this.evaluatee_test = { ...this.evaluateeTest };
      if (
        this.evaluateeTest.automated_score != null &&
        this.evaluateeTest.manual_score == null
      ) {
        this.evaluatee_test.manual_score = parseFloat(
          this.evaluateeTest.automated_score.toFixed(2)
        );
      }
    },
  },
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.file-container-special-scroll {
  overflow-y: auto;
  max-height: 60px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #e2e2e2;
}

::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.text-red {
  color: red;
}
.instrument-grade-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigate-space {
  margin-left: 1em;
  margin-right: 1em;
}
.navigate-left,
.navigate-right {
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.manual-score-input {
  /* transform: translate(-15%, -10%); */
  max-width: 6em;
}
.automated-score {
  font-weight: bold;
}
.btn-container {
  display: flex;
}
.btn-save,
.btn-publish-grading {
  width: 100%;
}
.btn-save {
  margin-right: 0.2em;
}
.btn-publish-grading {
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
  transition: all 0.3s;
  margin-left: 0.2em;
}
.btn-publish-grading:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
}
.published-question-test-score {
  display: block !important;
}
.instrument-form-feedback {
  width: calc(98%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 70px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  color: black;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.underline-class {
  text-decoration: underline;
}
@media (max-width: 991px) {
  .btn-container {
    display: block;
  }
  .btn-save,
  .btn-publish-grading {
    padding: 0.25rem 0 !important;
    width: 85%;
    margin-inline: 0 !important;
  }
}
</style>

